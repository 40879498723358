import { render, staticRenderFns } from "./GrabOrders.vue?vue&type=template&id=512a9697&scoped=true&"
import script from "./GrabOrders.vue?vue&type=script&lang=js&"
export * from "./GrabOrders.vue?vue&type=script&lang=js&"
import style0 from "./GrabOrders.vue?vue&type=style&index=0&id=512a9697&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "512a9697",
  null
  
)

export default component.exports